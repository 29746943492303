import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';

import ColorPickerModal from '../DeliveryModule/ColorPickerModal';
import ImageUploadComponent from '../../Common/ImageUploadComponent/ImageUploadComponent';
import Loader from '../../Common/Loader';

import { openColorModal } from '../../../actions/siteadmin/modalActions';
import { api, homepageUploadDir } from '../../../config';
import messages from '../../../locale/messages';
import validate from './validate';
import submit from './submit';

import colorPickerIcon from '../../../../public/Icons/colorPickerIcon.svg';

import s from './SafetySettingsForm.css';
export class SafetySettingsForm extends Component {

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, disabled }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} disabled={disabled} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<FormGroup className={cx(s.space5, 'positionRelative')}>
				<label>{label}</label>
				<FormControl
					{...input}
					className={className}
					placeholder={label}
					as="textarea"
					rows="4"
				>
					{children}
				</FormControl>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</FormGroup>
		);
	}

	render() {
		const { handleSubmit, safetyGridImage1, safetyGridImage2, safetyGridImage3, loading, submitting, userAppBgColor, openColorModal, imageLoader, playstorelogoImage, appStoreImage } = this.props;
		const { formatMessage } = this.props.intl;
		return (
				<Container fluid>
					<ColorPickerModal formName={'SafetySettingsForm'} />
					<h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.safetySectionSettings)}</h1>
					<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
					<div className={s.blackCenterSection}>
						<Row>
							{/* <Col xl={12} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}> */}
									{/* <Col lg={12} md={12} sm={12} xs={12}> */}
										{/* <Row> */}
											<Col lg={6} md={6} sm={12} xs={12}>
												<Field name="safetyGridTitle1" type="text" component={this.renderField} label={formatMessage(messages.userAppTitle)} />
												<Field name="safetyGridLink1" type="text" component={this.renderField} label={formatMessage(messages.playStoreLink)} />
												<div className={s.positionRelative}>
													<Field
														name="userAppBgColor"
														type="text"
														placeholder={formatMessage(messages.bgColor)}
														component={this.renderField}
														label={formatMessage(messages.bgColor)}
														labelClass={s.labelText}
														fieldClass={cx(s.formControlInput, 'pickerInput')}
													/>
													<div className={cx(s.pickerIcon, 'pickerIconRTL')} onClick={(e) => openColorModal('userAppBgColor', userAppBgColor)} ><img src={colorPickerIcon} /></div>
												</div>
											</Col>
											<Col lg={6} md={6} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<ImageUploadComponent
															className={cx(s.btnSecondary, s.profileNoPadding)}
															subTextClass={s.subText}
															subText={formatMessage(messages.maximumUploadSizeLabel)}
															defaultMessage={formatMessage(messages.chooseFile)}
															loaderName={'safetyimageLoader'}
															postUrl={api.apiEndpoint + '/uploadHomepageImage'}
															loader={imageLoader}
															fieldName={'safetyGridImage3'}
															formName={'SafetySettingsForm'}
															imageSrc={safetyGridImage3 ? api.apiEndpoint + homepageUploadDir + safetyGridImage3 : null}
															label={formatMessage(messages.safetyImage3)}
														/>
													</div>
												</Form.Group>
											</Col>
											<Col lg={6} md={6} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<ImageUploadComponent
															className={cx(s.btnSecondary, s.profileNoPadding)}
															subTextClass={s.subText}
															subText={formatMessage(messages.maximumUploadSizeLabel)}
															defaultMessage={formatMessage(messages.chooseFile)}
															loaderName={'playStoreImageLoader'}
															postUrl={api.apiEndpoint + '/uploadHomepageImage'}
															loader={playstorelogoImage}
															fieldName={'safetyGridImage1'}
															formName={'SafetySettingsForm'}
															imageSrc={safetyGridImage1 ? api.apiEndpoint + homepageUploadDir + safetyGridImage1 : null}
															label={formatMessage(messages.safetyImage1)}
														/>
													</div>
												</Form.Group>
											</Col>
											<Col lg={6} md={6} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<ImageUploadComponent
															className={cx(s.btnSecondary, s.profileNoPadding)}
															subTextClass={s.subText}
															subText={formatMessage(messages.maximumUploadSizeLabel)}
															defaultMessage={formatMessage(messages.chooseFile)}
															loaderName={'appStoreImageLoader'}
															postUrl={api.apiEndpoint + '/uploadHomepageImage'}
															loader={appStoreImage}
															fieldName={'safetyGridImage2'}
															formName={'SafetySettingsForm'}
															imageSrc={safetyGridImage2 ? api.apiEndpoint + homepageUploadDir + safetyGridImage2 : null}
															label={formatMessage(messages.safetyImage2)}
														/>
													</div>
												</Form.Group>
											</Col>
										{/* </Row> */}
									{/* </Col> */}
							{/* </Col> */}
							<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
								<Form.Group className={s.noMargin}>
									<div className={s.displayInlineBlock}>
										<Loader
											type={"button"}
											label={formatMessage(messages.submitButton)}
											show={loading}
											buttonType={'submit'}
											className={cx(s.button, s.btnPrimary)}
											disabled={submitting || loading}
											isSuffix={true}
										/>
									</div>
								</Form.Group>
							</Col>
						</Row>
						</div>
					</Form>
				</Container>
		)
	}
}

SafetySettingsForm = reduxForm({
	form: 'SafetySettingsForm',
	onSubmit: submit,
	validate
})(SafetySettingsForm);

const selector = formValueSelector('SafetySettingsForm')
const mapState = (state) => ({
	safetyGridImage1: selector(state, 'safetyGridImage1'),
	safetyGridImage2: selector(state, 'safetyGridImage2'),
	safetyGridImage3: selector(state, 'safetyGridImage3'),
	userAppBgColor: selector(state, 'userAppBgColor'),
	loading: state.loader.SafetySettingsForm,
	imageLoader: state.loader.safetyimageLoader,
	playstorelogoImage: state.loader.playStoreImageLoader,
	appStoreImage: state.loader.appStoreImageLoader
})

const mapDispatch = {
	openColorModal
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(SafetySettingsForm)));